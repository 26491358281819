<template>
  <div>
    <List
      title="Communities"
      resource="customerCommunities"
      class="community-list"
      ref="list"
      routeIdParam="communityId"
      :request-params="{customerId: customerId, deletedOnly: true}"
      :basePath="basePath"
      :fields="fields"
      :pageSize="20"
      on-row-click="details"
      :search="true"
      search-placeholder="Search for a community by name"
      infinity-scroll
    >
    </List>
  </div>
</template>

<script>
  import ModalNavigation from '@/mixins/ModalNavigation';
  import List from '@/components/auth/list/List';
  import DateTimeField from "@/components/auth/list/fields/DateTimeField";

  export default {
    name: "DeletedCommunitiesList",
    components: {
      List,
    },
    mixins: [ModalNavigation],
    computed: {
      basePath: function () {
        return this.$route.path;
      },
      customerId: function () {
        return this.$route.params.customerId;
      },
      fields: function () {
        return [
          {
            name: 'name',
            title: 'Name',
            sortField: 'name',
          },
          {
            name: 'ownerName',
            title: 'owner name',
            sortField: 'ownerName',
          },
          {
            name: DateTimeField,
            sortField: 'deletedAt',
            title: 'deleted at',
            switch: {
              source: 'deletedAt'
            }
          }
        ];
      }
    },
  }
</script>
